/**
 * Backend models for Opp Inventory
 */

import { AvailabilityType } from '@/api/inventory/InventoryModels';
import {
  DeliveryFormats,
  DeliveryMethods,
  RedemptionMethods,
} from '@/models/ApiV2Models';

/** Company */

export interface Company {
  name: string;
  orgId: string;
  website: string;
  version: number;
  meta: Meta;
  orgType?: string;
  externalSystemId?: string;
}

/** Address */

export interface Address {
  id?: string;
  city: string;
  countryCode: string;
  googlePlaceId: string;
  latitude: number;
  longitude: number;
  managed?: boolean;
  meta?: Meta;
  name: string;
  postalCode: string;
  region: string;
  streetAddress: string;
  version: number;
}

/** Contacts */

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  primary: boolean;
  role: string;
  website: string;
  emails: Array<ContactEmail>;
  phones: Array<ContactPhone>;
  addresses: Array<Address>;
  meta: Meta;
  version: number;
  showName?: string;
}

export interface ContactEmail {
  email: string;
  type: string;
}

export interface ContactPhone {
  countryCode: string;
  extension: string;
  number: string;
  type: string;
}

/** Product */

export interface Product {
  id: string;
  addressId?: string;
  availabilityType?: AvailabilityType;
  description?: string;
  internalName: string;
  locale?: string;
  meta?: Meta;
  version?: number;
  orgId: string;
  policy?: ProductPolicy;
  ticketDelivery?: TicketDelivery;
  timeZone?: string;
}

export interface TicketDelivery {
  redemptionMethod?: RedemptionMethods;
  deliveryFormats?: Array<DeliveryFormats>;
  deliveryMethods?: Array<DeliveryMethods>;
}

/** Option */

export interface Option {
  id: string;
  internalName: string;
  displayName?: string;
  description?: string;
  availabilityType?: AvailabilityType;
  meta: Meta;
  version?: number;
  orgId: string;
  policy?: ProductPolicy;
  sharesAvailabilityWithProduct: boolean;
  sharesCapacityWithProduct: boolean;
  unitPrices?: Array<OptionUnitPrice>;
}

/** Unit */

export interface DisplayUnit {
  value: string;
  text: string;
}

export interface OptionUnitPrice {
  unitId: string;
  amount: OptionUnitPriceAmount;
}
export interface OptionUnitPriceAmount {
  currency: string;
  net?: number | null;
  original?: number | null;
  retail?: number | null;
}

/** Trip Route **/

export interface TripRoute {
  arrival: string;
  departure: string;
  stops?: Array<string>;
  meta: Meta;
  version?: number;
}

/** Offer */

export interface Offer {
  id: string;
  name: string;
  description: string;
  meta: Meta;
  productId: string;
  optionId?: string;
  useProductPolicy: boolean;
  useOptionPolicy?: boolean;
  policy?: Policy;
  version?: number;
  addressName?: string;
  capacity?: Array<OfferCapacityInList>;
  open?: boolean;
  error?: boolean;
  errorDetails?: Array<string>;
  isLimitedCapacity?: boolean;
  inventoryName?: string;
  inventoryNameList?: OfferCapacityInventory[];
}

export interface OfferCapacityInList {
  offerId: string;
  id: string;
  name: string;
  path: string;
  status: string;
  open?: boolean;
}

export interface OfferCapacityInventory {
  id: string;
  name: string;
}

export interface OfferCapacityList {
  filter: {
    offerId?: Array<string>;
  };
  data: Array<OfferCapacityInList>;
}

export interface CapacityLimit {
  unlimited?: boolean;
  pooled?: number;
  byUnit?: any;
}

export interface OfferCapacity {
  id: string;
  name: string;
  offerId: string;
  scheduleId: string;
  validFrom: string;
  validUntil?: string | null;
  version?: number;
  timeSlots: Array<TimeSlot>;
  capacityLimit: CapacityLimit;
}
export interface OfferPricing {
  id: string;
  name: string;
  offerId: string;
  scheduleId: string;
  timeSlots: Array<TimeSlot>;
  unitPrices: Array<UnitPrices>;
  validFrom: string;
  validUntil?: string;
  version?: number;
  fees: Array<OfferFees>;
}

export interface PriceAmountUI {
  net?: number | null;
  original?: number | null;
  retail?: number | null;
}

export interface OfferPricingUI {
  unitId: string;
  total: PriceAmountUI;
  productPrice: PriceAmountUI;
  includedOptionPrice: PriceAmountUI;
  includedFees: PriceAmountUI;
}
export interface OfferPriceListItem {
  id: string;
  name: string;
  offerId: string;
  path?: string;
  status?: string;
  open?: boolean;
}
export interface OfferPricingList {
  filter: {
    offerId: string;
  };
  data: Array<OfferPriceListItem>;
}

export interface OfferFees {
  applicationOrder: number;
  taxFeeId: string;
}

export interface UnitPrices {
  unitId: string;
  amount: UnitPriceAmount;
}

export interface UnitPriceAmount {
  currency: string;
  net?: number | null;
  original?: number | null;
  retail?: number | null;
  includedFees?: Array<FeesPriceAmount>;
}

export interface FeesPriceAmount {
  name: string;
  original?: number | null;
  retail?: number | null;
  net?: number | null;
}

export interface TimeSlot {
  end: string;
  key?: string;
  start: string;
  weekday: string;
  editMode?: boolean;
  timeStartMenu?: false;
  timeEndMenu?: false;
}

export interface Policy {
  id: string;
  cancellable: boolean;
  refundable: boolean;
  cancelCutOffAfter?: number;
  cancelCutOffBefore?: number;
  cutOffTime: number;
  isProduct?: boolean;
}

export interface ProductPolicy {
  id: string;
  cancelCutOffAfter?: number;
  cancelCutOffBefore?: number;
  cancelPolicyDescription?: string;
  cancellable?: boolean;
  cutOffTime?: number;
  refundable?: boolean;
}

/** Product Unit Relationship **/

export interface ProductUnit {
  parentId: string;
  childId: string;
}

/** Capacity **/
export interface ProductCapacityPool {
  orgId: string;
  productId: string;
  capacityPool: CapacityPool;
}

export interface OptionCapacityPool {
  orgId: string;
  optionId: string;
  capacityPool: CapacityPool;
}

export interface CapacityPool {
  unlimited: boolean;
  pooled: number;
  byUnit: Map<string, string>;
}

export interface PoolStructure {
  unitId: string;
  value: string;
}

/** Misc */

export interface Meta {
  status: string;
  createdAt: string;
  updatedAt: string;
  version: number;
}

export interface RateCard {
  id: string;
  name: string;
  offers: Array<string>;
  adjustment?: PriceCalculation;
  validFrom: string;
  validUntil?: string;
  offerNames?: Array<string>;
  offerLocations?: Array<string>;
  meta?: Meta;
  version?: number;
  channels?: Array<RateCardChannel>;
}

export interface RateCardChannel {
  id: string;
  name: string;
}

export interface PriceCalculation {
  source?: string;
  destination?: string;
  calculationType?: string;
  value?: number | null;
}
export interface EffectiveDateRange {
  validFrom: string;
  validUntil?: string | null;
}

export interface Fee {
  amount: number;
  applicationLevel: string;
  applicationOrder: number;
  chargeType: string;
  collectible: boolean;
  currency: string;
  description: string;
  id: string;
  includedInPricing: boolean;
  meta?: Meta;
  name: string;
  optionId: string;
  orgId: string;
  productId: string;
  rateType: string;
  ruleType: string;
  validFrom: string;
  validUntil?: string;
  calcText?: string;
  supplierId: string;
  effectiveDateRange: {
    from: string;
    until: string;
  };
  disclosureRequired: boolean;
  status: number;
  createdAt: string;
  updatedAt: string;
  version: number; //only for put requests
}
export interface Schedule {
  id: string;
  name: string;
  validFrom: string;
  validUntil?: string | null;
  timeSlots: Array<TimeSlot>;
}

export interface GRSchedule {
  id: string;
  name: string;
  path: string;
  open?: boolean;
}

export interface SchedulesList {
  data: Array<GRSchedule>;
}

export interface CompanyRate {
  offer: {
    id: string;
    name: string;
  };
  product: {
    id: string;
    name: string;
    ext: any;
  };
  rate: {
    id: string;
    name: string;
    ext: any;
  };
  supplier: {
    id: string;
    name: string;
    ext: any;
  };
  open: boolean;
  id: string;
}

export interface ChannelBinding {
  channel: {
    id: string;
    name: string;
    ext: any;
  };
  product: {
    id: string;
    name: string;
    ext: any;
  };
  rateIds: Array<string>;
  supplier: {
    id: string;
    name: string;
    ext: any;
  };
}

export interface ChannelSpr {
  id: string;
  supplier: {
    id: string;
    name: string;
    ext?: any;
  };
  product: {
    id: string;
    name: string;
    ext?: any;
  };
  rate: {
    id: string;
    name: string;
    ext?: any;
  };
  rateCardId: string;
}

export enum ChannelBindingMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface ChannelBindingRQ {
  mode: ChannelBindingMode;
  channelID: string;
  channelName: string;
  supplierID: string;
  productID: string;
  rateIDs: Array<string>;
  rateCardID: string;
  validFrom?: string;
  validUntil?: string;
}

export interface OperatorUser {
  id: string;
  name: string;
  config: any;
}

export interface Event {
  EntityType: string;
  EntityID: string;
  Path: string;
  DetailsPath: string;
}
/** Timezone */
export interface TimezoneAbbreviation {
  daylightSaving: string;
  standard: string;
}
export interface CountryTimezone {
  abbreviation: TimezoneAbbreviation;
  countryCode: string;
  countryCodeIso: string;
  name: string;
  value: string;
  offset: TimezoneAbbreviation;
}
