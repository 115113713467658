












































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RdmDateRangePicker from '@/components/RdmDateRangePicker.vue';
import RdmStatusIndicator from '@/components/RdmStatusIndicator.vue';
import { BookingsReportFilter, BookingReport } from '@/models/Report';
import { DatePickerOptionsDateRange, DateSearchTypeList } from '@/utils/componentHelpers';
import { httpModule } from '@/store/modules/moduleHttp';
import { companyModule } from '@/store/modules/moduleCompany';
import { reportModule } from '@/store/modules/moduleReport';
import { tablePaginationOptions } from '@/utils/helpers';
import xlsx, { IJsonSheet } from 'json-as-xlsx';

@Component({
  components: {
    RdmStatusIndicator,
    RdmDateRangePicker,
  },
})
export default class AllBookingsReport extends Vue {
  public search = '';
  public reportHeaders: Array<Record<string, any>> = [
    { text: 'Arrival Date', align: 'left', sortable: true, value: 'arrivalDate' },
    { text: 'Booking Date', align: 'left', sortable: true, value: 'bookingDate' },
    { text: 'Channel', align: 'left', sortable: true, value: 'channel' },
    { text: 'Booking ID', sortable: true, value: 'bookingID' },
    { text: 'Traveler Name', sortable: true, value: 'travelerName' },
    { text: 'Status', sortable: true, value: 'bookingStatus' },
  ];

  private data = [] as any[];
  private allData = [] as any[];
  private products = [] as string[];
  private channels = [] as string[];
  private selectedChannel = '';
  private selectedProduct = '';
  private totalBookings = 0;
  private totalNet = '';
  private totalGross = '';
  private totalCancelledBookings = 0;
  private totalCancelledNet = '';
  private totalCancelledGross = '';
  private options = DatePickerOptionsDateRange(this.$i18n);
  private dateRange = [] as Array<Date>;
  private datePick = new Date().toDateString();
  private dateRangeType = 'today';
  private dateSearchTypeList = [
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Last Week',
      value: 'week',
    },
    {
      label: 'Last Month',
      value: 'month',
    },
    ...DateSearchTypeList(this.$i18n),
  ];
  private downloadSettings = {
    fileName: `all_bookings_report_${this.datePick}`,
    extraLength: 3,
  };

  private filters: BookingsReportFilter = new BookingsReportFilter();

  get Loading() {
    return reportModule.Loading;
  }

  mounted() {
    this.filters.target = companyModule.Company.externalSystemId || '';
    this.clearValues();
  }

  download() {
    const data: IJsonSheet[] = [
      {
        sheet: 'All Bookings Report',
        columns: [
          { label: 'Reseller Ref#', value: 'resellerBookingRef' },
          { label: 'Supplier Ref#', value: 'supplierBookingRef' },
          { label: 'Redeam Ref#', value: 'bookingID' },
          { label: 'Booking Date', value: 'bookingDate' },
          { label: 'Arrival Date', value: 'arrivalDate' },
          { label: 'Channel', value: 'channel' },
          { label: 'Location', value: 'location' },
          { label: 'Product', value: 'product' },
          { label: 'Option', value: 'option' },
          { label: 'Traveler Name', value: 'travelerName' },
          { label: 'Traveler Email', value: 'travelerEmail' },
          { label: 'Traveler Phone', value: 'travelerPhone' },
          { label: 'Traveler Type', value: 'travelerType' },
          { label: 'Traveler Count', value: 'travelerCount' },
          { label: 'Retail Sales', value: 'grossSales' },
          { label: 'Net Sales', value: 'netSales' },
          { label: 'Currency', value: 'currency' },
          { label: 'Status', value: 'bookingStatus' },
          { label: 'Redemption Count', value: 'redemptionCount' },
          { label: 'Time Redeemed', value: 'timeRedeemed' },
        ],
        content: [{ organization: '' }],
      },
    ];
    this.data.forEach((report) => {
      report?.items?.forEach((item: any) => {
        data[0].content.push({
          resellerBookingRef: report.resellerBookingRef,
          supplierBookingRef: report.supplierBookingRef,
          bookingID: report.bookingID,
          bookingDate: report.bookingDate,
          arrivalDate: report.arrivalDate,
          channel: report.channel,
          location: item.supplier,
          product: item.product,
          option: item.rate,
          travelerName: report.travelerName,
          travelerEmail: report.travelerEmail,
          travelerPhone: report.travelerPhone,
          travelerType: item.travelerType,
          travelerCount: item.quantity,
          grossSales: this.formatAmount(item.grossSales, item.currency),
          netSales: this.formatAmount(item.netSales, item.currency),
          currency: item.currency,
          bookingStatus: report.bookingStatus,
          redemptionCount: item.redemptionCount,
          timeRedeemed: report.timeRedeemed,
        });
      });
    });
    xlsx(data, this.downloadSettings);
  }

  updateSummaryData() {
    const bookings = this.data.filter((report: any) => !report.isCancelled);
    this.totalBookings = bookings.length;

    this.totalGross = bookings
      .map((itm) => itm.grossSales)
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toString();
    this.totalGross = parseInt(this.totalGross).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.totalNet = bookings
      .map((itm) => itm.netSales)
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toString();
    this.totalNet = parseInt(this.totalNet).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const cancelledBookings = this.data.filter((report: any) => report.isCancelled);
    this.totalCancelledBookings = cancelledBookings.length;
    this.totalCancelledGross = cancelledBookings
      .map((itm) => itm.grossSales)
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toString();
    this.totalCancelledGross = parseInt(this.totalCancelledGross).toLocaleString(
      'en-US',
      { style: 'currency', currency: 'USD' },
    );
    this.totalCancelledNet = cancelledBookings
      .map((itm) => itm.netSales)
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toString();
    this.totalCancelledNet = parseInt(this.totalCancelledNet).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.getProducts();
    this.getChannels();
  }

  searchBookings() {
    httpModule.searchBookingsReport(this.filters).then(() => {
      const data = reportModule.Reports?.items?.map((report: BookingReport) => {
        return {
          bookingID: report.bookingID,
          bookingStatus: report.bookingStatus,
          bookingDate: report.bookingDate,
          arrivalDate: report.arrivalDate,
          resellerBookingRef: report.resellerBookingRef,
          supplierBookingRef: report.supplierBookingRef,
          organization: report.organization,
          channel: report.channel,
          travelerName: report.travelerName,
          travelerEmail: report.travelerEmail,
          travelerPhone: report.travelerPhone,
          travelerCount: report.travelerCount,
          netSales: report.totalNetSales,
          grossSales: report.totalGrossSales,
          currency: report.currency,
          redemptionCount: report.totalRedemptions,
          timeRedeemed: report.timeRedeemed,
          isCancelled: report.bookingStatus == 'STATUS_CANCELLED',
          items: report.items,
        };
      });
      this.data = data ? data.flat(1) : [];
      this.allData = data ? data.flat(1) : [];
      this.updateSummaryData();
    });
  }

  /**
   * formatAmount - formats the amount value to the 'pretty' value for display
   * @param amount
   * @param currency
   */
  formatAmount(amount: number, currency: string): string {
    return amount?.toLocaleString('en-US', { style: 'currency', currency });
  }

  getProducts() {
    const p: string[] = [];
    this.allData.forEach((rp: any) =>
      rp.items.forEach((itm: any) => p.push(itm.product)),
    );
    p.unshift('All');
    this.products = p;
  }

  getChannels() {
    const p: string[] = [];
    p.push(...this.allData.map((itm: { channel: string }) => itm.channel));
    p.unshift('All');
    this.channels = p;
  }

  changeChannel(channel: string) {
    if (channel === 'All') {
      this.data = this.allData;
    } else {
      this.selectedChannel = channel;
      this.data = this.allData.filter((br: { channel: any }) => br.channel === channel);
    }

    if (this.selectedProduct.length) {
      this.data = this.data.filter(
        (br) =>
          br.items.filter((itm: any) => itm.product === this.selectedProduct).length > 0,
      );
    }
    this.updateSummaryData();
  }

  changeProduct(product: string) {
    if (product === 'All') {
      this.data = this.allData;
    } else {
      this.selectedProduct = product;
      this.data = this.allData.filter(
        (br) => br.items.filter((itm: any) => itm.product === product).length > 0,
      );
    }

    if (this.selectedChannel.length) {
      this.data = this.data.filter((br) => br.channel === this.selectedChannel);
    }
    this.updateSummaryData();
  }

  changeDates(range: string) {
    if (range === 'rangeSelect' && this.dateRangeType === 'dateRange') {
      const from = new Date(this.dateRange[0]);
      from.setDate(from.getDate());
      const to = new Date(this.dateRange[1]);
      to.setDate(to.getDate() + 1);
      this.filters.start = from.toISOString().slice(0, 10);
      this.filters.end = to.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (range === 'dateSelect') {
      if (this.dateRangeType === 'fromDate') {
        const fromDate = new Date(this.datePick);
        fromDate.setDate(fromDate.getDate() - 1);
        this.filters.start = fromDate.toISOString().slice(0, 10);
        this.filters.end = '';
        this.searchBookings();
      }

      if (this.dateRangeType === 'untilDate') {
        const untilDate = new Date(this.datePick);
        untilDate.setDate(untilDate.getDate() + 1);
        this.filters.start = '';
        this.filters.end = untilDate.toISOString().slice(0, 10);
        this.searchBookings();
      }
    }
  }

  refresh() {
    this.searchBookings();
  }

  private clearValues() {
    this.dateRange = [];
    this.datePick = '';

    if (this.dateRangeType === 'today') {
      this.datePick = new Date().toDateString();
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.dateRange = [today, tomorrow];
      this.filters.start = today.toISOString().slice(0, 10);
      this.filters.end = tomorrow.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (this.dateRangeType === 'week') {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 8);
      this.dateRange = [weekAgo, today];
      this.filters.start = weekAgo.toISOString().slice(0, 10);
      this.filters.end = today.toISOString().slice(0, 10);
      this.searchBookings();
    }

    if (this.dateRangeType === 'month') {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);
      this.dateRange = [monthAgo, today];
      this.filters.start = monthAgo.toISOString().slice(0, 10);
      this.filters.end = today.toISOString().slice(0, 10);
      this.searchBookings();
    }
  }

  get PageTitle() {
    return this.$route?.meta?.title || '';
  }

  get paginationOptions() {
    return tablePaginationOptions(this.data);
  }
}
