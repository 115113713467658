import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { first } from 'lodash';
import {tap, finalize, catchError} from 'rxjs/operators';
import Store from '../../index';
import {
  RedemptionStatus,
  messages,
  normalizeTicket,
  validateTicketStatus,
  validatePassStatus,
  normalizePassIndex,
  passStatus,
} from '../../../utils/web-redemption';
import {
  RequestPostValidate,
  ResponsePostValidate,
} from '../../../api/wps/BackplaneModels';
import {ApiError, handleErrs, StatusEnum} from '../../../api/ErrHandlers';
import { Alert } from '../../../utils/alert';
import { DefaultAlertErrHandler } from '../../../utils/error-handling';
import { nonProductionConsoleLog } from '@/utils/helpers';
import backplaneClient from "@/api/backplane/BackplaneClient";

@Module({
  dynamic: true,
  store: Store,
  name: 'web-redemption',
  namespaced: true,
})
export default class WebRedemptionModule extends VuexModule {
  private status: RedemptionStatus = RedemptionStatus.STATUS_NOT_STARTED;

  private message: any = null;

  private ticketList: any[] = [];

  private rawTicketList: any[] = [];

  private normalizaedTicketList: any[] = [];

  private passList: any[] = [];

  private rawPassList: any[] = [];

  private productList: any[] = [];

  private clarificationType = '';

  private selectedTicket: any = null;

  private selectedRawTicket: any = null;

  private selectedPass: any = null;

  private selectedRawPass: any = null;

  private selectedProduct: any = null;

  private validation: ResponsePostValidate = {};

  private loading = false;

  private lastValidated: any = null;

  @Mutation
  public setTickets(tickets: any[]) {
    tickets.forEach((tkt: any, index: number) => {
      const nTkt = normalizeTicket(tkt, index);
      this.ticketList.push(tkt);
      this.normalizaedTicketList.push(nTkt);
    });
  }

  get tickets() {
    return this.normalizaedTicketList;
  }

  @Mutation
  public setLoading(loading: boolean) {
    this.loading = loading;
  }

  get getLoading() {
    return this.loading;
  }

  @Mutation
  public setPasses(passes: any[]) {
    passes.forEach((pass: any, index: number) => {
      const nPass = normalizePassIndex(pass, index);
      this.passList.push(nPass);
    });
  }

  get passes() {
    return this.passList;
  }

  @Mutation
  public setRawTickets(tickets: any[]) {
    this.rawTicketList = tickets;
  }

  get rawTickets() {
    return this.rawTicketList;
  }

  @Mutation
  public setRawPasses(passes: any[]) {
    this.rawPassList = passes;
  }

  get rawPasses() {
    return this.rawPassList;
  }

  @Mutation
  public setProducts(products: any[]) {
    this.productList = products;
  }

  @Mutation
  public setProduct(product: any) {
    this.selectedProduct = product;
  }

  get getSelectedProduct() {
    return this.selectedProduct;
  }

  get products() {
    return this.productList;
  }

  @Mutation
  public setSelectedTicketOrPass() {
    this.selectedTicket = first(this.normalizaedTicketList);
    this.selectedRawTicket = first(this.rawTicketList);
    this.selectedPass = first(this.passList);
    this.selectedRawPass = first(this.rawPassList);
  }

  @Mutation
  public setSelectedTicket(ticket: any) {
    this.selectedTicket = ticket;
  }

  @Mutation
  public setSelectedPass(pass: any) {
    this.selectedPass = pass;
  }

  @Mutation
  public setSelectedRawTicket(ticket: any) {
    this.selectedRawTicket = ticket;
  }

  @Mutation
  public setSelectedRawPass(pass: any) {
    this.selectedRawPass = pass;
  }

  @Mutation
  public setValidation(validation: ResponsePostValidate) {
    this.validation = validation;
  }

  get getSelectedTicket() {
    return this.selectedTicket;
  }

  get getSelectedRawTicket() {
    return this.selectedRawTicket;
  }

  get getSelectedPass() {
    return this.selectedPass;
  }

  get getSelectedRawPass() {
    return this.selectedRawPass;
  }

  get getValidation() {
    return this.validation;
  }

  @Mutation // TODO update this to pass in type and index
  public setLastValidated(last: any) {
    this.lastValidated = last;
  }

  get getLastValidated() {
    return this.lastValidated;
  }

  @Mutation // TODO update this to pass in type and index
  public setStatus(status: any) {
    this.status = status;
  }

  get getStatus() {
    return this.status;
  }

  @Mutation
  public setMessage(msg: any) {
    this.message = msg;
  }

  get getMessage() {
    return this.message;
  }

  @Mutation
  public setClarificationType(type: any) {
    this.clarificationType = type;
  }

  get getClarificationType() {
    return this.clarificationType;
  }

  @Action({ rawError: true })
  async getTicketsAndPasses(voucherRef: string) {
    this.reset();
    this.setLoading(true);



    return backplaneClient
      .getValidations(voucherRef, {
        ...DefaultAlertErrHandler(''),
        onValidationNotFound() {
          throw new Alert('Message.RedemptionTicketNotFound');
        },
      })
      .pipe(
        tap((validation:any) => {
          console.log('getTicketsAndPasses tap', validation);
          const tickets: any[] = validation.tickets || [];
          const passes: any[] = validation.pass || [];

          this.setTickets(tickets);
          this.setRawTickets(tickets);
          this.setPasses(passes);
          this.setRawPasses(passes);
          this.setSelectedTicketOrPass();
          if (
            (this.normalizaedTicketList || []).length + (this.passList || []).length ===
            1
          ) {
            // todo pick the first ticket or pass and call sync validate
          }
        }),
        finalize(() => this.setLoading(false)),
      )
      .toPromise();
  }

  @Mutation
  reset() {
    this.selectedTicket = null;
    this.selectedPass = null;
    this.selectedRawTicket = null;
    this.selectedRawPass = null;
    this.selectedProduct = null;
    this.validation = {};
    this.ticketList = [];
    this.normalizaedTicketList = [];
    this.passList = [];
    this.productList = [];
    this.rawPassList = [];
  }

  @Action
  async validate(ifRaw: boolean) {
    nonProductionConsoleLog('moduleWebRedemption - validate', this.getSelectedTicket);
    const type = this.getSelectedTicket ? 'ticket' : 'pass';
    try {
      let data;
      let selectedTicket;
      if (ifRaw) {
        nonProductionConsoleLog(
          'moduleWebRedemption - validate raw',
          ifRaw,
          ' type',
          type,
        );
        if (type === 'ticket') {
          selectedTicket = this.getSelectedRawTicket;
        }
      } else {
        nonProductionConsoleLog('moduleWebRedemption - validate raw', ifRaw);
        nonProductionConsoleLog(
          'moduleWebRedemption - validate getSelectedTicket',
          this.getSelectedTicket,
        );
        nonProductionConsoleLog(
          'moduleWebRedemption - validate ticketList',
          this.ticketList,
        );
        selectedTicket = this.ticketList[this.getSelectedTicket.id];
        nonProductionConsoleLog(
          'moduleWebRedemption - validate selectedTicket',
          selectedTicket,
        );
      }

      if (selectedTicket) {
        data = {
          ticket: selectedTicket,
        };
      }

      if (ifRaw && this.selectedRawPass) {
        data = {
          pass: this.selectedRawPass,
        };
      } else if (!ifRaw && this.selectedPass) {
        data = {
          pass: this.selectedPass,
        };
      } else if (ifRaw && this.selectedRawTicket) {
        data = {
          ticket: this.selectedRawTicket,
        };
      }

      nonProductionConsoleLog('moduleWebRedemption - validate raw', ifRaw, ' data', data);
      // todo: Refactor to use raw product data similar to raw pass

      if (this.selectedProduct) {
        data = {
          ...data,
          clarification: {
            type: this.clarificationType,
            item: this.selectedProduct,
          },
        };
      }
      nonProductionConsoleLog(
        'moduleWebRedemption - validate selectedProduct',
        this.selectedProduct,
        ' data',
        data,
      );

      nonProductionConsoleLog(
        'moduleWebRedemption - validate selectedTicket',
        this.selectedTicket,
      );
      const ticketError =
        this.selectedTicket && validateTicketStatus(this.selectedTicket.status);

      if (ticketError) {
        this.setMessage(ticketError);
        return;
      }
      // todo: refactor to check for multiple options before validating

      const validationResponse = await backplaneClient
        .makeValidation(data as RequestPostValidate)
        .toPromise();
      nonProductionConsoleLog(
        'moduleWebRedemption - validate validationResponse',
        validationResponse,
      );

      if (ifRaw) {
        this.setValidation(validationResponse);
        const pStatus = this.getValidation.pass_status || null; // todo add ticket status
        if (
          type === 'pass' &&
          pStatus &&
          pStatus === passStatus.STATUS_NEEDS_CLARIFICATION
        ) {
          this.extractProducts();
          return;
        }

        if (type === 'pass') {
          const ticketErr = this.selectedRawPass && validatePassStatus(pStatus);
          if (ticketErr) {
            this.setMessage(ticketErr);
            return;
          }
        }
      }
      const details = validationResponse.uiMessage?.details || [];
      const activityInfo = details.find((el) => el.label === 'Activity:') || {};
      const activity = activityInfo.info;
      if (type === 'ticket') {
        this.selectedTicket.activity = activity;
        this.setLastValidated(this.selectedTicket);
      } else {
        this.selectedPass.activity = activity;
        this.setLastValidated(this.selectedPass);
      }

      this.setMessage(type === 'ticket' ? messages.TICKET_VALID : messages.PASS_VALID);
      this.setStatus(RedemptionStatus.STATUS_VALIDATED);
      this.reset();
    } catch (err) {
      nonProductionConsoleLog('moduleWebRedemption - validate catch err', err);
      if (err.status) {
        nonProductionConsoleLog('error', err.status);
        nonProductionConsoleLog(err.response);
        if (err.response && err.response.data) {
          const resPassStatus = err.response?.data?.passStatus;
          const products = err.response?.data?.clarification?.items;
          const clarificationType = err.response?.data?.clarification?.type;
          // todo test adding type ticket handler
          // if (type === 'ticket') {
          //     this.setMessage(
          //       err.status >= 500 ? messages.ERROR : messages.TICKET_NOT_VALID,
          //     );
          //     return;
          //   } else {
          if (err.status === 300 && products && products.length === 1) {
            this.setProducts(products);
            this.setClarificationType(clarificationType);
            this.setProduct(first(this.products));
            this.validate(false);
            return;
          }
          if (err.status !== 300) {
            nonProductionConsoleLog('setting message', resPassStatus);
            this.setMessage(validatePassStatus(resPassStatus) || messages.ERROR);
          }
          // }
          // if (type === 'ticket') {
          //   this.setMessage(
          //     err.status >= 500 ? messages.ERROR : messages.TICKET_NOT_VALID,
          //   );
          // } else {
          //   if (err.status === 300 && products && products.length === 1) {
          //     this.setProducts(products);
          //     this.setClarificationType(clarificationType);
          //     this.setProduct(first(this.products));
          //     this.validate(false);
          //     return;
          //   }
          //   if (err.status !== 300) {
          //     this.setMessage(validatePassStatus(resPassStatus) || messages.ERROR);
          //   }
          // }
          this.setProducts(products);
          this.setClarificationType(clarificationType);
        } else {
          const e: ApiError = err;
          if (e.status === 401) {
            throw new Alert('Unauthorized to do this action');
          } else if (
            err.status === StatusEnum.NETWORK_FAILED ||
            err.status === StatusEnum.ABORTED
          ) {
            throw new Alert('Network Error');
          }
          throw err;
        }
      } else {
        throw err;
      }
    }
  }

  @Action({ rawError: true })
  extractProducts() {
    const products = this.getValidation.clarification?.items || [];
    const clarificationType = this.getValidation.clarification?.type;
    this.setProducts(products);
    this.setClarificationType(clarificationType);
  }
}
