/* eslint-disable */

// TODO: find a way to consolidate similar rules and pass in a message
import moment from 'moment/moment';
import _ from 'lodash';
import { OppPricingAmount } from '@/api/inventory/InventoryModels';
import { DisplayUnitPrice } from '@/models';

/**
 * PASSWORD_REGEX
 *
 * At least 12 characters—the more characters, the better.
 * A mixture of both uppercase and lowercase letters.
 * A mixture of letters and numbers.
 * Inclusion of at least one special character, e.g.[, ! @ # ? ]
 * ^                               start anchor
 * (?=(.*[a-z]){1,})               lowercase letters. {1,} indicates that you want 1 of this group
 * (?=(.*[A-Z]){1,})               uppercase letters. {1,} indicates that you want 1 of this group
 * (?=(.*[0-9]){1,})               numbers. {1,} indicates that you want 2 of this group
 * (?=(.*[!@#$%^&*()\-__+.]){1,})  all the special characters in the [] fields. The ones used by regex are escaped by using the \ or the character itself. {1,} is redundant, but good practice, in case you change that to more than 1 in the future. Also keeps all the groups consistent
 * {12,}                            indicates that you want 12 or more
 * $                               end anchor
 */
const PASSWORD_REGEX = new RegExp(/^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{12,}$/);


export const companyNameRules: Array<any> = [
  (v: string) => !!v || 'Company name is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Company name is required',
];
export const orgCodeRules: Array<any> = [
  (v: string) => !!v || 'ORG is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'ORG is required',
];
export const firstNameRules: Array<any> = [
  (v: string) => !!v || 'First Name is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'First Name is required',
];
export const lastNameRules: Array<any> = [
  (v: string) => !!v || 'Last Name is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Last Name is required',
];
export const emailRules: Array<any> = [
  (v: string) => !!v || 'Primary Contact E-mail Address is required',
  (v: string) => /.+@.+\..+/.test(v) || 'Contact E-mail must be valid',
];
export const phoneRules: Array<any> = [
  (v: string) => !!v || 'Contact Phone is required',
  (v: string) =>
    /\(\d{3}\)\s\d{3}-\d{4}/.test(v) ||
    /\d{10}/.test(v) ||
    /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/.test(
      v,
    ) ||
    'Primary Contact Phone Number must be valid',
];

export const phoneCountryCodeRules: Array<any> = [
  (v: string) => !!v || 'Country Code is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Country Code is required',
];

export const optionalPhoneRules: Array<any> = [
  (v: string) =>
    !v ||
    v.length === 0 ||
    /\(\d{3}\)\s\d{3}-\d{4}/.test(v) ||
    /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/.test(
      v,
    ) ||
    'Phone Number must be valid',
];

export const optionalInternationalPhoneRules: Array<any> = [
  (v: string) =>
    !v ||
    v.length === 0 ||
    /\(\d{3}\)\s\d{3}-\d{4}/.test(v) ||
    /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/.test(
      v,
    ) ||
    'Phone Number must be valid',
];

export const urlRules: Array<any> = [
  (v: string) => !!v || 'Company URL is required',
  (v: string) => /.+\..+/.test(v) || 'Must be a valid URL',
];

export const ticketingSystemRules: Array<any> = [
  (v: string) => !!v || 'Ticketing system is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Ticketing system is required',
];

export const organizationRules: Array<any> = [
  (v: string) => !!v || 'Organization is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Organization is required',
];

export const userLoginRules: Array<any> = [
  (v: string) => !!v || 'E-mail is required',
  (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid', // Use if username must always be an email address
];

export const passwordRules: Array<any> = [
  (v:string) => !!v || 'Password is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Password is required',
  (v: string) => PASSWORD_REGEX.test(v) || "Password must be at least 12 characters and contain mixed case letters, numbers, and special characters.",
]

export const passwordLoginRules: Array<any> = [
  (v: string) => !!v || 'Password is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Password is required',
];

export function passwordMatchRules(password: string): Array<any> {
  return [
    (v: string) => !!v || 'Password is required',
    (v: string) => (v && v.toString().trim().length > 0) || 'Password is required',
    (v: string) => v === password || 'Passwords must match',
  ];
}

export const contactRules: Array<any> = [
  (v: string) => !!v || 'Contact method is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Contact method is required',
];

export const streetRules: Array<any> = [
  (v: string) => !!v || 'Street Address is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Street Address is required',
];

export const cityRules: Array<any> = [
  (v: string) => !!v || 'City is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'City is required',
];

export const stateRules: Array<any> = [
  (v: string) => !!v || 'State is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'State is required',
];

export const countryRules: Array<any> = [
  (v: string) => !!v || 'Country is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Country is required',
];

export const zipRules: Array<any> = [
  (v: string) => !!v || 'Zip code is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Zip code is required',
];

export const floatRules: Array<any> = [
  (v: string) => {
    return !isNaN(parseFloat(v)) || 'Invalid value';
  },
];

export const locationPeopleRules: Array<any> = [
  (v: number[]) => !!v || 'Location is required',
  (v: number[]) => v.length > 0 || 'Location is required',
];

export const locationNameRules: Array<any> = [
  (v: string) => !!v || 'Location name is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Location name is required',
];

export const locationDescriptionRules: Array<any> = [
  (v: string) => !!v || 'Location description is required',
  (v: string) =>
    (v && v.toString().trim().length > 0) || 'Location description is required',
];

export const productNameRules: Array<any> = [
  (v: string) => !!v || 'Product name is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Product name is required',
];

export const productCodeRules: Array<any> = [
  (v: string) => !!v || 'Product code is required',
  (v: string) => (v && v.toString().trim().length > 0) || 'Product code is required',
];

export const productDescriptionRules: Array<any> = [
  (v: string) => !!v || 'Product description is required',
  (v: string) =>
    (v && v.toString().trim().length > 0) || 'Product description is required',
];

export const productActivityTypeRules: Array<any> = [
  (v: string) => !!v || 'Product activity type is required',
  (v: string) =>
    (v && v.toString().trim().length > 0) || 'Product activity type is required',
];

export const productAvailScheduleRules: Array<any> = [
  (v: string) => !!v || 'Product availability schedule is required',
  (v: string) =>
    (v && v.toString().trim().length > 0) || 'Product availability schedule is required',
];

export const productDurationRules: Array<any> = [
  (v: number) => v != 0 || 'Cannot be less than 0',
];

export const operatorDescriptionRules: Array<any> = [
  (v: string) => {
    if (!v) return true;
    return v.length < 1000 || 'Description is too long';
  },
];

export function descriptionLimitRules(limit: number): Array<any> {
  return [
    (v: string) => !!v || 'This is required',
    (v: string) => (v && v.toString().trim().length > 0) || 'This is required',
    (v: string) => (v && v.toString().trim().length < limit) || 'Description is too long',
  ];
}

export function optionalDescriptionLimitRules(limit: number): Array<any> {
  return [
    (v: string) =>
      !v || (v && v.length === 0) || (v && v.length < limit) || 'Description is too long',
  ];
}

export const requiredFieldRules: Array<any> = [
  (v: string) => !!v || 'This is required!',
  (v: Array<any>) => (v && v.toString().trim().length > 0) || 'This is required!',
];

export const requiredAtLeastOneElementFieldRules: Array<any> = [
  (v: Array<any>) => !!v || 'This is required!',
  (v: Array<any>) => (v && v.length > 0) || 'This is required!',
];

export const requiredMultiSelectFieldRules: Array<any> = [
  (v: string[]) => !!v || 'This is required!',
  (v: string[]) => (v && v.length > 0) || 'This is required!',
];

export const requiredOnlyPositiveWholeNumbersFieldRules: Array<any> = [
  (v: string) => !!v || 'This is required!',
  (v: Array<any>) => (v && v.toString().trim().length > 0) || 'This is required!',
  (v: string) => /^\d\d*$/.test(v) || 'Only positive values',
];

export const ageRules: Array<any> = [(v: number) => v >= 0 || 'Cannot be less than 0'];

export const optionalNumberMinimumRules: Array<any> = [
  (v: number) => v >= 0 || 'Cannot be less than 0',
];
export const greaterThan1Rules: Array<any> = [
  (v: string) => !!v || 'This is required!',
  (v: string) => _.toNumber(v) >= 1 || 'Cannot be less than 1',
];

export function requiredNumberFieldRules(minimum: number): Array<any> {
  return [
    (v: number) => !!v || 'This is required!',
    (v: number) => v >= minimum || `Cannot be less than ${minimum}`,
  ];
}

export function requiredMaxNumberFieldRules(maximum: number): Array<any> {
  return [
    (v: number) => !!v || 'This is required!',
    (v: number) => v > 0 || `Must be greater than 0`,
    (v: number) => v <= maximum || `Inventory count can not be greater than the max capacity`,
  ];
}

export function requiredStateRules(country: string): Array<any> {
  const requiredStateCountries = ['USA', 'AUS', 'CAN'];
  const isRequired = requiredStateCountries.find((c) => {
    return c.toLowerCase() === country.toLowerCase();
  });
  if (isRequired)
    return [
      (v: string) => !!v || 'This is required!',
      (v: Array<any>) => (v && v.toString().trim().length > 0) || 'This is required!',
    ];
  else return [];
}

export function requiredNotLessThenPrice(value?: number): Array<any> {
  return [
    (v: number) => v > -101 || 'Can`t be more then 100% discount'
  ]
}

export function requiredNumberRetailNetOriginalFieldRules(
  retail: number | undefined,
  net: number | undefined,
  original: number | undefined,
): Array<any> {
  return [
    (v: number) =>
      retail !== undefined ||
      net !== undefined ||
      original !== undefined ||
      `Must have a price`,
    (v: number) =>
      (retail !== undefined && retail > 0) ||
      (net !== undefined && net > 0) ||
      (original !== undefined && original > 0) ||
      `Must have a price`,
  ];
}

export function requiredNumberOppPricingFieldRules(amount: OppPricingAmount): Array<any> {
  return [
    (v: number) =>
      amount.retail !== undefined ||
      amount.net !== undefined ||
      amount.original !== undefined ||
      `Must have a price`,
    (v: number) =>
      (amount.retail !== undefined && amount.retail > 0) ||
      (amount.net !== undefined && amount.net > 0) ||
      (amount.original !== undefined && amount.original > 0) ||
      `Must have valid a price`,
  ];
}

export function ageStringRules(min: string | number, max: string | number): Array<any> {
  return [
    (v: string) => /[0-9]{1,3}/.test(v) || 'Enter a valid amount',
    (v: string) => Number.isInteger(_.toNumber(v)) || 'Enter a valid amount',
    (v: string) => (_.toNumber(v) >= 0 && _.toNumber(v) <= 150) || 'Enter a valid amount',
    () => (_.toNumber(max) > _.toNumber(min)) || 'Maximum age must be greater than the minimum age'
  ];
}

export const validWholeNumbersRules: Array<any> = [
  (v: string) => /^\d\d*$/.test(v) || 'Enter a valid amount',
  (v: string) => Number.isInteger(_.toNumber(v)) || 'Enter a valid amount',
];

export function requiredStartTimeBeforeEndTimeRules(s: string, e: string): Array<any> {
  const start = s || '';
  const end = e || '';
  const endTime = moment(`2020-01-01 ${end} +0000`, 'YYYY-MM-DD HH:mm Z');
  return [
    (v: string) => !!v || 'This is required!',
    () =>
      moment(`2020-01-01 ${start} +0000`, 'YYYY-MM-DD HH:mm Z').isBefore(
        endTime,
        'minute',
      ) ||
      start.length === 0 ||
      end.length === 0 ||
      `Close time cannot be earlier than ${start}`,
  ];
}

export function requiredStartDateBeforeEndDateRules(s: string, e: string): Array<any> {
  const start = s || '';
  const end = e || '';
  const endTime = moment(`${end}`, 'YYYY-MM-DD HH:mm Z');
  return [
    (v: string) => !!v || 'This is required!',
    () =>
      moment(`${start}`, 'YYYY-MM-DD HH:mm Z').isBefore(endTime, 'minute') ||
      start.length === 0 ||
      end.length === 0 ||
      `End date cannot be earlier than ${start}`,
  ];
}

export function requiredStartDateBeforeTodayDateRules(s: string, e: string): Array<any> {
  const start = s || '';
  const end = e || '';
  const endTime = moment(`${end}`, 'YYYY-MM-DD HH:mm Z');
  return [
    (v: string) => !!v || 'This is required!',
    () =>
      moment(`${start}`, 'YYYY-MM-DD HH:mm Z').isBefore(endTime, 'minute') ||
      start.length === 0 ||
      end.length === 0 ||
      `Start date cannot be earlier than ${start}`,
  ];
}

export function optionalStartDateBeforeEndDateRules(s: string, e: string): Array<any> {
  const start = s || '';
  const end = e || '';
  const endTime = moment(`${end}`, 'YYYY-MM-DD HH:mm Z');
  return [
    (v: string) => !v || !!v || 'This is required!',
    (v: string) =>
      !v ||
      moment(`${start}`, 'YYYY-MM-DD HH:mm Z').isBefore(endTime, 'minute') ||
      start.length === 0 ||
      end.length === 0 ||
      `End date cannot be earlier than ${start}`,
  ];
}

export function onlyOneUnitPriceRequired(unit: DisplayUnitPrice): Array<any> {
  return [
    (v: any) =>
      unit.retail !== undefined ||
      unit.net !== undefined ||
      unit.original !== undefined ||
      `Must have a price`,
    (v: any) =>
      (unit.retail !== undefined &&
        _.isNumber(+unit.retail) &&
        _.toNumber(unit.retail) > 0) ||
      (unit.net !== undefined && _.isNumber(+unit.net) && _.toNumber(unit.net) > 0) ||
      (unit.original !== undefined &&
        _.isNumber(+unit.original) &&
        _.toNumber(unit.original) > 0) ||
      `Must have valid a price`,
  ];
}
